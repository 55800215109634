/* eslint react/jsx-no-target-blank: "off" */
import PropTypes from "prop-types"
import { useRouter } from "next/router"
import classNames from "classnames"
import Link from "next/link"
import { getUrlAndType } from "@lib/utils"

import { ReactSVG } from "react-svg"
import { useNinetailed } from "@lib/custom-hooks/use-ninetailed"

import {
  button,
  icon_new_tab,
  linktag,
  link_new_tab,
  theme_default,
  theme_inverted,
  theme_arrow_light,
  theme_arrow_dark,
  theme_outline_light,
  theme_outline_dark,
  theme_arrow_horizontal_dark,
  theme_arrow_horizontal_light
} from "./styles.module.scss"

const themeOptions = {
  default: theme_default,
  inverted: theme_inverted,
  "arrow-light": theme_arrow_light,
  "arrow-dark": theme_arrow_dark,
  "outline-light": theme_outline_light,
  "outline-dark": theme_outline_dark,
  "arrow-horizontal-dark": theme_arrow_horizontal_dark,
  "arrow-horizontal-light": theme_arrow_horizontal_light,
  dark: theme_inverted
}

// -------------------------------------------------------

const Button = (props) => {
  const {
    category,
    children,
    className,
    labelAttr,
    theme,
    url,
    onClick,
    isDisabled,
    reload,
    spread
  } = props
  const router = useRouter()

  const { track } = useNinetailed()

  const handleClick = (category = "button_click", currentUrl, label, destinationUrl) => {
    window._paq
      ? window._paq.push([
          "trackEvent",
          category,
          `Current Page: ${currentUrl}`,
          `${label} - ${destinationUrl}`
        ])
      : null

    if (window.drift) window.drift.page()

    track(category, { destination: destinationUrl })

    // eslint-disable-next-line
    dataLayer.push({
      event: category,
      current_url: currentUrl,
      destination_url: destinationUrl
    })
  }

  const classes = classNames(button, {
    [themeOptions[theme]]: themeOptions[theme],
    [className]: className
  })

  const containerClass = classNames({ [spread]: spread })

  // Renders a standard <a> tag with Next's built-in Link component if a URL is provided

  if (url) {
    const { url: convertedUrl, isExternal, showIcon } = getUrlAndType(url)
    // do not use nextJS' link component if disabled
    const pdf = convertedUrl.slice(-3)
    const buttonLabel = labelAttr ?? children
    const isArrowHorizontalButton =
      theme === "arrow-horizontal-dark" || theme === "arrow-horizontal-light"
    const isPillButton =
      theme === "default" ||
      theme === "dark" ||
      theme === "outline-light" ||
      theme === "outline-dark"

    if (isExternal || reload) {
      return (
        <a
          data-cy={props?.testid}
          href={url}
          className={`${classNames(classes, linktag, isExternal ? link_new_tab : null)}`}
          label={labelAttr}
          target={isExternal ? "_blank" : null}
          rel={pdf === "pdf" ? "nofollow" : isExternal ? "noreferrer" : null}
        >
          {children || labelAttr || "Submit"}
          {isPillButton && showIcon && (
            <ReactSVG
              className={icon_new_tab}
              src="/icons/external-link.svg"
              title="Open in a new tab"
              wrapper="span"
              beforeInjection={(svg) => {
                svg.setAttribute("style", "width: 14px")
                svg.setAttribute("style", "height: 14px")
              }}
            />
          )}
        </a>
      )
    } else {
      return (
        <Link href={convertedUrl} className={containerClass}>
          <button
            data-cy={props?.testid}
            className={`${classes} ${linktag}`}
            label={labelAttr}
            onClick={() => handleClick(category, router.asPath, buttonLabel, url)}
          >
            {children || labelAttr || "Submit"}
            {isArrowHorizontalButton && (
              <ReactSVG
                src={`/icons/arrow-right.svg`}
                wrapper="span"
                beforeInjection={(svg) => {
                  svg.setAttribute("style", "width: 14px")
                  svg.setAttribute("style", "height: 14px")
                }}
              />
            )}
          </button>
        </Link>
      )
    }
  }

  // If no URL is provided, it defaults to the button/onclick functionality
  const properties = {
    className: classes,
    disabled: isDisabled,
    label: labelAttr
  }

  if (onClick) properties.onClick = (e) => onClick(e)

  return (
    <button {...properties} data-cy={props.testid}>
      {children || "Submit"}
    </button>
  )
}

Button.propTypes = {
  /**
   * className css styles used on the button
   */
  className: PropTypes.string,
  /**
   * Text rendered to the screen inside the button.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /**
   * inheritted from content block to spread the buttons if there are mutliple
   */
  spread: PropTypes.string,

  /**
   * Specifies the theme of button
   */
  theme: PropTypes.oneOf([
    "arrow-dark",
    "arrow-horizontal-dark",
    "arrow-horizontal-light",
    "arrow-light",
    "dark",
    "default",
    "inverted",
    "outline-light",
    "outline-dark"
  ]),
  /**
   * Specifies the button label attribute
   */
  labelAttr: PropTypes.string,
  /**
   * Specifies the event category for tracking
   */
  category: PropTypes.string,
  /**
   * Specifies where the url links (external links start with http, internal links start with "/").
   * If no URL is provided, it will use the onClick functionality instead.
   */
  url: PropTypes.string,
  /**
   * Specifies the event to be triggered on button click. Ignored if a URL is provided.
   */
  onClick: PropTypes.func,
  /**
   * (Only works when onClick is provided & no URL is provided)
   * Specifies the button's able/disable state. Only buttons with an action (no URL) can be disabled.
   */
  isDisabled: PropTypes.bool,
  reload: PropTypes.bool,
  testid: PropTypes.string
}

Button.defaultProps = {
  theme: "default",
  isDisabled: false,
  reload: false
}

export default Button
