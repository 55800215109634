export default {
  formHeading:
    "Sign up for monthly updates on brands, data, and life in the age of customer-centricity.",
  lists: [
    {
      listItems: [
        {
          label: "Platform",
          subheader: true
        },
        {
          label: "Lakehouse Customer Data Platform",
          url: "/platform/customer-data-platform"
        },
        {
          label: "AmpID",
          url: "/platform/identity-resolution"
        },
        {
          label: "Amp360",
          url: "/platform/customer-360"
        },
        {
          label: "AmpIQ",
          url: "/platform/data-intelligence-and-activation"
        },
        {
          label: "AmpAi",
          url: "/platform/ai"
        },
        {
          label: "DataGrid",
          url: "/platform/data-infrastructure"
        },
        {
          label: "Capabilities",
          subheader: true
        },
        {
          url: "/capabilities/data-warehouse",
          label: "Data Sharing"
        },
        {
          url: "/capabilities/real-time-personalization",
          label: "Real-time"
        },
        {
          url: "/integrations",
          label: "Integrations"
        },
        {
          url: "/capabilities/security-and-privacy",
          label: "Security & privacy"
        },
        {
          label: "Patents",
          subheader: true
        },
        {
          url: "/patents",
          label: "Proprietary Technology"
        }
      ]
    },
    {
      listItems: [
        {
          label: "By Use Case",
          subheader: true
        },

        {
          label: "Paid Media Optimization",
          url: "/solutions/use-cases/paid-media-optimization"
        },
        {
          label: "Personalization",
          url: "/solutions/use-cases/omnichannel-personalization"
        },
        {
          label: "Loyalty",
          url: "/solutions/use-cases/elevating-loyalty"
        },
        {
          label: "Customer Care",
          url: "/solutions/use-cases/customer-care"
        },
        {
          label: "By Function",
          subheader: true
        },
        {
          label: "Marketing",
          url: "/solutions/departments/marketing"
        },
        {
          label: "Developers",
          url: "/solutions/departments/it"
        },
        {
          label: "Analytics",
          url: "/solutions/departments/analytics"
        },
        {
          label: "By Industry",
          subheader: true
        },
        {
          label: "Retail",
          url: "/solutions/industries/retail"
        },
        {
          label: "Travel & Hospitality",
          url: "/solutions/industries/travel-hospitality"
        },
        {
          label: "Restaurants",
          url: "/solutions/industries/restaurants"
        },
        {
          label: "Financial Services",
          url: "/solutions/industries/financial-services"
        }
      ]
    },
    {
      listItems: [
        {
          label: "Company",
          subheader: true
        },
        {
          label: "About Amperity",
          url: "/company"
        },
        {
          label: "Newsroom",
          url: "/newsroom"
        },
        {
          url: "/awards",
          label: "Awards"
        },
        {
          label: "Careers",
          url: "/careers"
        },
        {
          label: "Contact",
          url: "/contact"
        },
        {
          label: "Resources",
          subheader: true
        },
        {
          label: "All Resources",
          url: "/resources"
        },
        {
          label: "Customer Stories",
          url: "/customers"
        },
        {
          label: "Blog",
          url: "/blog"
        },
        {
          label: "CDP A to Z",
          url: "https://amperity.com/cdp-a-to-z"
        },
        {
          label: "Events",
          url: "/events"
        },
        {
          label: "Webinars",
          url: "/resources/webinars"
        },
        {
          label: "Documentation",
          url: "https://docs.amperity.com/"
        }
      ]
    },
    {
      listItems: [
        {
          label: "Partners",
          subheader: true
        },
        {
          url: "/partners",
          label: "Partner Ecosystem"
        },
        {
          url: "/partners/cloud-partners",
          label: "Cloud Partners"
        },
        {
          url: "/partners/technology-partners",
          label: "Technology Partners"
        },
        {
          url: "/partners/solutions-partners",
          label: "Solutions Partners"
        },
        {
          url: "https://learn.amperity.com/page/partner-learning-homepage",
          label: "Partner Learning Lab"
        },
        {
          label: "Legal & Privacy",
          subheader: true
        },
        {
          label: "Legal",
          url: "/legal"
        },
        {
          label: "Privacy Notice",
          url: "/legal/privacy-notice"
        },
        {
          label: "Cookie Preferences",
          url: "javascript:Cookiebot.renew()"
        },
        {
          label: "Cookie Declaration",
          url: "/legal/cookie-declaration"
        }
      ]
    }
  ]
}
